<template>
    <div>
        <div class="group__1">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup1') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3">進捗ステータス <span class="text-danger">*</span></label>
                    <div class="col-sm-5">
                        <div style="position: relative">
                            <Multiselect
                                :id="`select-progres-status`"
                                :value.sync="progressStatus"
                                :options="campaignStatus"
                                :config.sync="configTabBasic.configFieldStatusCampaign"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">キャンペーン名称<span class="text-danger">*</span></label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="form.name"
                            :config="{
                                isRequired: !validate.form.name.required,
                                isMaxLength: !validate.form.name.maxLength,
                                error: submitted && validate.form.name.$error,
                                errorField: $t('campaign.name')
                            }"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">ZACID</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="form.zac_id"
                            :config="{
                                errorField: 'ZACID',
                                isMaxLength: !validate.form.zac_id.maxLength,
                                error: submitted && validate.form.zac_id.$error
                            }"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.sfid') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="form.sfid"
                            :config="{
                                isMaxLength: !validate.form.sfid.maxLength,
                                error: submitted && validate.form.sfid.$error,
                                errorField: $t('pageCampaign.sfid')
                            }"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">キャンペーン種別</label>
                    <div class="col-sm-9">
                        <div class="d-flex">
                            <div v-for="item in listDataMasterCampaignType" :key="item.id + 's'">
                                <InputRadio
                                    class="mr-4"
                                    :model.sync="form.campaign_type.id"
                                    :name="`CampaignType`"
                                    :id="`CampaignType-${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    @change="getDataCampaignTye(item)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row" v-if="form.campaign_type.id">
                    <label class="col-sm-3">応募種別</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div v-for="item in listDataMasterCampaignTypeChild" :key="item.id + 'sss'">
                                <InputCheckBox
                                    class="mr-3 mb-2"
                                    :model.sync="listCategories"
                                    :id="`check-CampaignTypeChild_${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    @change="setCondition1()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">受注範囲</label>
                    <div class="col-sm-9">
                        <button type="button" class="btn btn-sm btn-info" @click="handleOpenScopeOrder()">詳細</button>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.overview_information" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="group__2 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup2') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3 pr-0">キャンペーン開始年月日</label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-campaign-start-date-time"
                            :full_date.sync="form.campaign_start_date"
                            :full_time.sync="form.campaign_start_time"
                            :config.sync="form.configCampaignStartDateTime"
                            @change="handleCampaignDateTime('start')"
                        >
                            <span v-if="form.configCampaignStartDateTime.error" class="invalid-feedback d-block">{{
                                form.configCampaignStartDateTime.errorDateTimeStartCampaign
                            }}</span>
                        </DateTime>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3" for="validationCustom05">キャンペーン終了年月日</label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-campaign-end-date-time"
                            :full_date.sync="form.campaign_end_date"
                            :full_time.sync="form.campaign_end_time"
                            :config.sync="form.configCampaignEndDateTime"
                            @change="handleCampaignDateTime('end')"
                        >
                            <span v-if="form.configCampaignEndDateTime.error" class="invalid-feedback d-block">{{
                                form.configCampaignEndDateTime.errorDateTimeEndCampaign
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3" for="validationCustom05">事務局開始年月日</label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-secretariat-start-date-time"
                            :full_date.sync="form.secretariat_start_date"
                            :full_time.sync="form.secretariat_start_time"
                            :config.sync="form.configSecretariatStartDateTime"
                        >
                            <span v-if="form.configSecretariatStartDateTime.error" class="invalid-feedback d-block">{{
                                form.configSecretariatStartDateTime.errorDateTimeStartSecretariat
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3" for="validationCustom05">事務局終了年月日</label>
                    <div class="col-sm-9">
                        <DateTime
                            id="select-secretariat-end-date-time"
                            :full_date.sync="form.secretariat_end_date"
                            :full_time.sync="form.secretariat_end_time"
                            :config.sync="form.configSecretariatEndDateTime"
                        >
                            <span v-if="form.configSecretariatEndDateTime.error" class="invalid-feedback d-block">{{
                                form.configSecretariatEndDateTime.errorDateTimeEndSecretariat
                            }}</span>
                        </DateTime>
                    </div>
                </div>
                <div v-if="form.isShowReceiptExpirationDate">
                    <div class="form-group row">
                        <label class="col-sm-3 pr-0">レシート有効期限開始</label>
                        <div class="col-sm-9">
                            <DateTime
                                :id="`receiptExpirationStartDate`"
                                :full_date.sync="form.receipt_expiration_start_date"
                                :full_time.sync="form.receipt_expiration_start_time"
                                :config.sync="form.configReceiptExpirationStartDateTime"
                            >
                                <div v-if="form.configReceiptExpirationStartDateTime.error" class="invalid-feedback d-block">
                                    <span>{{ form.configReceiptExpirationStartDateTime.errorText }}</span>
                                </div>
                            </DateTime>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 pr-0">レシート有効期限終了</label>
                        <div class="col-sm-9">
                            <DateTime
                                :id="`receiptExpirationEndDate`"
                                :full_date.sync="form.receipt_expiration_end_date"
                                :full_time.sync="form.receipt_expiration_end_time"
                                :config.sync="form.configReceiptExpirationEndDateTime"
                            >
                                <div v-if="form.configReceiptExpirationEndDateTime.error" class="invalid-feedback d-block">
                                    <span>{{ form.configReceiptExpirationEndDateTime.errorText }}</span>
                                </div>
                            </DateTime>
                        </div>
                    </div>
                </div>

                <div v-if="form.isShowPostcardDate" class="form-group row">
                    <label class="col-sm-3 pr-0">ハガキ応募の場合</label>
                    <div class="col-sm-9">
                        <div class="d-flex">
                            <InputCheckBox
                                class="mr-3 mb-2"
                                :model.sync="form.is_post_effective_date"
                                :id="`check_post_effective_date`"
                                :value="form.is_post_effective_date"
                                :label="`当日消印有効`"
                            />
                            <InputCheckBox
                                class="mr-3 mb-2"
                                :model.sync="form.is_before_due_date"
                                :id="`check_before_due_date`"
                                :value="form.is_before_due_date"
                                :label="`必着`"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.period_related" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="listCategoriesSub?.length > 0" class="group__3 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup3') }}】</h5>
            <div class="ml-4 mb-3">
                <div v-for="(item, k) in listCategoriesSub" :key="k + item.category_id + 's'">
                    <div class="mb-1" style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px">
                        <div class="row">
                            <label class="col-sm-3">{{ renderNameCategory(item) }}</label>
                        </div>

                        <div v-if="renderNameCategory(item) == 'WEB'" class="form-group row">
                            <label class="col-sm-3">応募フォームURL</label>
                            <div class="col-sm-9">
                                <InputText
                                    :model.sync="form.application_form"
                                    :config="{
                                        errorField: '応募フォームURL',
                                        maxlength: 255,
                                        error: false
                                    }"
                                />
                            </div>
                        </div>

                        <div v-if="renderNameCategory(item) == 'その他'" class="form-group row">
                            <label class="col-sm-3"></label>
                            <div class="col-sm-9">
                                <textarea rows="3" v-model="item.extension.other_type_data" class="form-control"></textarea>
                            </div>
                        </div>

                        <div
                            v-if="
                                renderNameCategory(item) == 'WEB' ||
                                renderNameCategory(item) == 'ハガキ' ||
                                renderNameCategory(item) == 'X' ||
                                renderNameCategory(item) == 'Instagram' ||
                                renderNameCategory(item) == 'LINE' ||
                                renderNameCategory(item) == 'TikTok' ||
                                renderNameCategory(item) == '店頭' ||
                                renderNameCategory(item) == 'アプリ' ||
                                renderNameCategory(item) == 'はがき'
                            "
                            class="form-group row"
                        >
                            <label class="col-sm-3">応募条件①</label>
                            <div class="col-sm-5">
                                <div style="position: relative">
                                    <Multiselect
                                        :id="`select-application-condition-1${k}`"
                                        :value.sync="item.application_condition_1"
                                        :options="item.listCondition1"
                                        :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                        @close="setCondition2(item)"
                                        @remove="handleRemove(item)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            class="row mb-3"
                            v-if="item.application_condition_1 && item.application_condition_1.id && item.application_condition_1?.value !== 'その他'"
                        >
                            <label class="col-sm-3">応募条件②</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-wrap">
                                    <div v-for="jtem in item.listCondition2" :key="jtem.id + 'sss'">
                                        <InputCheckBox
                                            class="mr-3 mb-2"
                                            :model.sync="item.application_condition_2"
                                            :id="`check_application_condition_2_${jtem.id}`"
                                            :value="jtem.id"
                                            :label="jtem.value"
                                            @change="handleShowConditionBuyAndSell('change', item.listCondition2, jtem)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="item.application_condition_1?.value == 'その他'" class="form-group row">
                            <label class="col-sm-3">{{ item.application_condition_1?.value }}</label>
                            <div class="col-sm-9">
                                <textarea rows="5" class="form-control" v-model="item.extension.content_other"></textarea>
                            </div>
                        </div>

                        <div v-for="ic2 in item.listCondition2" :key="`${ic2.id}-ll`">
                            <div v-if="onHandleIsVisibleOtherC2(ic2, item.application_condition_2)" class="form-group row">
                                <label class="col-sm-3"> {{ ic2?.value }}</label>
                                <div class="col-sm-9">
                                    <textarea rows="5" class="form-control" v-model="ic2.note.other_content"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-3">応募方法</label>
                            <div class="col-sm-9">
                                <textarea rows="5" class="form-control" v-model="item.extension.application_method"></textarea>
                            </div>
                        </div>

                        <div class="form-group row mt-2" v-if="item.checkSNS >= 0">
                            <label class="col-sm-3" for="validationCustom05">公式アカウント名</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.extension.offical_name_sns"
                                    :class="{
                                        'is-invalid': submitted && validate.listCategoriesSub.$each[k].extension.offical_name_sns.$error
                                    }"
                                    :placeholder="`●●公式アカウント`"
                                    @change="handleChangeSocialID(item)"
                                />
                                <div
                                    v-if="submitted && validate.listCategoriesSub.$each[k].extension.offical_name_sns.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!validate.listCategoriesSub.$each[k].extension.offical_name_sns.maxLength">{{
                                        $t('validateField.max255', {
                                            field: '公式アカウント名'
                                        })
                                    }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-2" v-if="item.checkSNS >= 0">
                            <label class="col-sm-3" for="validationCustom05">公式アカウントID</label>
                            <div class="col-sm-9">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="item.extension.offical_id_sns"
                                    :class="{
                                        'is-invalid': submitted && validate.listCategoriesSub.$each[k].extension.offical_id_sns.$error
                                    }"
                                    :placeholder="`@●●`"
                                    @change="handleChangeSocialID(item)"
                                />
                                <div v-if="submitted && validate.listCategoriesSub.$each[k].extension.offical_id_sns.$error" class="invalid-feedback">
                                    <span v-if="!validate.listCategoriesSub.$each[k].extension.offical_id_sns.maxLength">{{
                                        $t('validateField.max255', {
                                            field: '公式アカウントID'
                                        })
                                    }}</span>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="renderNameCategory(item) == 'X' || renderNameCategory(item) == 'TikTok' || renderNameCategory(item) == 'Instagram'"
                            class="form-group row"
                        >
                            <label class="col-sm-3">ハッシュタグ</label>
                            <div class="col-sm-9">
                                <KeyWordTag2
                                    :id="`${item.category_id}-tag-arrayTag`"
                                    checkPre="hash"
                                    :model.sync="item.configHashTag.arrayTag"
                                    :config.sync="configFieldHashtags"
                                >
                                    <span v-if="configFieldHashtags.error">
                                        {{ configFieldHashtags.errorCustomMess }}
                                    </span>
                                </KeyWordTag2>
                            </div>
                        </div>

                        <a
                            href="javascript:void(0)"
                            style="position: absolute; right: 10px; top: 10px"
                            @click="deleteCategory(item)"
                            class="text-danger"
                            >削除</a
                        >
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.application_details" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="form.campaign_type.id" class="group__4 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup4') }}</h5>
            <div class="ml-4">
                <div v-if="form.isShowConditionBuyAndSell">
                    <div class="form-group row">
                        <label class="col-sm-3">購買条件</label>
                        <div class="col-sm-9">
                            <div class="d-flex flex-wrap mb-3">
                                <div v-for="item in listDataMasterConditionPurchase1" :key="item.id + 'pt'">
                                    <InputCheckBox
                                        class="mr-3 mb-2"
                                        :model.sync="form.purchase_conditions"
                                        :id="`check_purchase_conditions_${item.id}`"
                                        :value="item.id"
                                        :label="item.value"
                                        @change="handleClearDataPurchaseConditions(item)"
                                    />
                                </div>
                            </div>

                            <div v-for="item in form.purchase_conditions" :key="'target' + item">
                                <div class="form-group row">
                                    <label class="col-sm-3">{{ renderNamePurchase(item) }} 入力 </label>
                                    <div class="col-sm-9">
                                        <div class="d-flex align-items-center">
                                            <input
                                                v-model="purchaseConditions[item]"
                                                class="form-control mr-1"
                                                @input="
                                                    () => {
                                                        $emit('update:purchase_conditions', purchaseConditions);
                                                    }
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <div v-if="form.isShowReceiptExpirationDate" class="col-sm-3">
                        <label>{{ $t('pageCampaign.targetProductCategoryClose') }}</label>
                        <div class="">
                            {{ $t('pageCampaign.noteTargetProductCategoryClose') }}
                        </div>
                    </div>
                    <div v-else class="col-sm-3">
                        <label>{{ $t('pageCampaign.targetProductCategoryOpen') }}</label>
                        <div class="">
                            {{ $t('pageCampaign.noteTargetProductCategoryOpen') }}
                        </div>
                    </div>

                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap mb-3">
                            <div v-for="item in listDataMasterCategoryProductTarget" :key="item.id + 'pt'">
                                <InputCheckBox
                                    class="mr-3 mb-2"
                                    :model.sync="form.listCheckedTargetLv1"
                                    :id="`check_data_target_product_lv1_${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    @change="handleChangeDataTargetProductLv1(item)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="form.target_product_lists.length > 0">
                    <div v-for="masterTarget in listDataMasterCategoryProductTarget" :key="masterTarget.id + 'master'">
                        <div v-if="form.listCheckedTargetLv1.includes(masterTarget.id)" class="form-group row">
                            <label class="col-sm-3">{{ masterTarget.value }}</label>
                            <div class="col-sm-9">
                                <div class="d-flex flex-wrap">
                                    <div v-for="masterTarget_child in masterTarget.data" :key="masterTarget_child.id + 'master_child_target'">
                                        <InputCheckBox
                                            class="mr-3 mb-2"
                                            :model.sync="form.listCheckedTargetLv2"
                                            :id="`check_data_target_product_lv2_${masterTarget_child.id}`"
                                            :value="masterTarget_child.id"
                                            :label="masterTarget_child.value"
                                            @change="handleChangeDataTargetProductLv2(masterTarget_child, masterTarget.id)"
                                        />
                                    </div>
                                </div>
                                <div v-if="form.campaign_type.value == 'クローズ'">
                                    <div v-for="masterTarget_child in masterTarget.data" :key="masterTarget_child.id + 'master_child_target'">
                                        <div v-if="form.listCheckedTargetLv2.includes(masterTarget_child.id)" class="form-group mt-3 p-0">
                                            <label class="col-sm-3">{{ masterTarget_child.value }}</label>
                                            <div class="col-sm-9">
                                                <textarea
                                                    row="3"
                                                    class="form-control"
                                                    v-model="handleGetNoteTargetProduct(masterTarget_child.id, masterTarget.id).product_name"
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.product_related" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="group__5 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup5') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3">告知</label>
                    <div class="col-sm-9">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="公式サイト／POP／公式SNS／デジタル・ウェブ広告"
                            v-model="form.inform"
                            :class="{
                                'is-invalid': submitted && validate.form.inform.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.inform.$error" class="invalid-feedback">
                            <span v-if="!validate.form.inform.maxLength">
                                {{
                                    $t('validateField.max255', {
                                        field: '告知'
                                    })
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">LP サイト</label>
                    <div class="col-sm-9">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.url"
                            :class="{
                                'is-invalid': submitted && validate.form.url.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.url.$error" class="invalid-feedback">
                            <span v-if="!validate.form.url.maxLength">
                                {{
                                    $t('validateField.incorrectURL', {
                                        field: 'LP サイト'
                                    })
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="form.isShowPublicTime" class="form-group row">
                    <label class="col-sm-3">公開期間 </label>
                    <div class="col-sm-9">
                        <div class="d-flex mb-3" style="gap: 10px">
                            <DateTime
                                id="select-url-publish-time-from"
                                :full_date.sync="form.url_publish_time_from"
                                :full_time.sync="form.url_publish_time_from_time"
                                :config.sync="form.configUrlPublishTimeFrom"
                            >
                                <span v-if="form.configUrlPublishTimeFrom.error" class="invalid-feedback d-block">{{
                                    form.configUrlPublishTimeFrom.errorText
                                }}</span>
                            </DateTime>
                            <div>
                                <span>から</span>
                            </div>
                        </div>
                        <div class="d-flex" style="gap: 10px">
                            <DateTime
                                id="select-url-time-to"
                                :full_date.sync="form.url_publish_time_to"
                                :full_time.sync="form.url_publish_time_to_time"
                                :config.sync="form.configUrlPublishTimeTo"
                            >
                                <span v-if="form.configUrlPublishTimeTo.error" class="invalid-feedback d-block">{{
                                    form.configUrlPublishTimeTo.errorText
                                }}</span>
                            </DateTime>
                            <div>
                                <span>まで</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-3">
                        <label>{{ $t('pageCampaign.ipInformationItem') }}</label>
                        <p class="mb-0" style="font-size: 12px">タレントやキャラクター等を利用しているかどうか</p>
                    </div>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap" style="gap: 12px">
                            <div v-for="(itemIP, idxIP) in listIPInformationItem" :key="idxIP">
                                <InputRadio
                                    v-if="form.information_ips.length > 0"
                                    class="mr-4"
                                    :model.sync="form.information_ips[0].master_id"
                                    :name="`infomationIP`"
                                    :id="`infomationIP-${itemIP.id}`"
                                    :value="itemIP.id"
                                    :label="itemIP.value"
                                    @change="handleChangeRadioInfomationIP(form.information_ips[0], itemIP.value)"
                                />
                            </div>
                        </div>
                        <div v-if="form.information_ips[0]?.value == 'あり'" class="my-3">
                            <div v-for="(itemIP, idxIP) in listIPInformationItem" :key="idxIP">
                                <div v-if="itemIP.data.length > 0">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <div v-for="(dataItemIP, idxDataIP) in itemIP.data" :key="`${idxDataIP}- ${idxIP}`">
                                            <InputCheckBox
                                                class="mr-3 mb-2"
                                                :model.sync="form.information_ips[0].checkedBox"
                                                :id="`check_data_child_ip${dataItemIP.id}`"
                                                :value="dataItemIP.id"
                                                :label="dataItemIP.value"
                                                @change="handleChangeListDataIP(form.information_ips[0], dataItemIP)"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <template>
                                <div v-for="(ipsItem, idx) in form.information_ips[0]?.children_data" :key="`ips_${idx}`" class="form-group row">
                                    <label class="col-sm-3">{{ ipsItem.value }}</label>
                                    <div class="col-sm-9">
                                        <textarea rows="3" v-model="ipsItem.note" class="form-control"></textarea>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.campaignSponsorInformation') }}</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap" style="gap: 12px">
                            <div v-for="(sponsor, idxSponsor) in listSponsorInformation" :key="idxSponsor">
                                <InputRadio
                                    v-if="form.sponsor_informations.length > 0"
                                    class="mr-4"
                                    :model.sync="form.sponsor_informations[0].master_id"
                                    :name="`sponsorInformation`"
                                    :id="`sponsorInformation-${sponsor.id}`"
                                    :value="sponsor.id"
                                    :label="sponsor.value"
                                    @change="handleChangeSponsor(form.sponsor_informations[0], sponsor.value)"
                                />
                            </div>
                        </div>
                        <div class="mt-3">
                            <!-- タイアップキャンペーン -->
                            <div v-if="form.sponsor_informations[0]?.value == 'タイアップキャンペーン'">
                                <div>
                                    <div class="form-group row">
                                        <label class="col-sm-3">{{ $t('pageCampaign.circulationNumber') }}</label>
                                        <div class="col-sm-9">
                                            <div class="d-flex w-100">
                                                <div class="col-sm-6 pl-0">
                                                    <InputText
                                                        :model.sync="form.sponsor_informations[0].note.circulation_number"
                                                        :config.sync="form.sponsor_informations[0].note.configCirculationNumber"
                                                    />
                                                </div>
                                                <div class="col-sm-9 row align-items-baseline">
                                                    <label class="col-sm-1" style="white-space: nowrap; min-width: 97px">{{
                                                        $t('pageCampaign.distributionName')
                                                    }}</label>
                                                    <div class="col-sm-6">
                                                        <textarea
                                                            rows="3"
                                                            v-model="form.sponsor_informations[0].note.circulation_distribution"
                                                            class="form-control"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="form-group row">
                                        <label class="col-sm-3">{{ $t('pageCampaign.tie-upManufacturers') }}</label>
                                        <div class="col-sm-9">
                                            <div class="d-flex w-100">
                                                <div class="col-sm-6 pl-0">
                                                    <InputText
                                                        :model.sync="form.sponsor_informations[0].note.up_manufacturers"
                                                        :config.sync="form.sponsor_informations[0].note.configUpManufacturers"
                                                    />
                                                </div>
                                                <div class="col-sm-9 row align-items-baseline">
                                                    <label class="col-sm-1" style="white-space: nowrap; min-width: max-content">{{
                                                        $t('pageCampaign.manufacturerName')
                                                    }}</label>
                                                    <div class="col-sm-6">
                                                        <textarea
                                                            rows="3"
                                                            v-model="form.sponsor_informations[0].note.up_manufacturers_name"
                                                            class="form-control"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="form.sponsor_informations[0]?.value == 'その他'">
                                <div class="form-group row">
                                    <label class="col-sm-3">{{ form.sponsor_informations[0]?.value }}</label>
                                    <div class="col-sm-5">
                                        <textarea rows="3" v-model="form.sponsor_informations[0].note.content_other" class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.announcement_site_related" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="group__6 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup6') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <div class="col-sm-3">
                        <label>当選者数</label>
                        <div class="">
                            {{ $t('pageCampaign.noteNumberOfWinners') }}
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <input class="form-control" type="text" :disabled="true" :value="form.total_number_of_win_prize" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">賞品進呈時期(発送時期・送付時期)</label>
                    <div class="col-sm-9">
                        <input
                            class="form-control"
                            type="text"
                            v-model="form.prize_shipping_period"
                            :class="{
                                'is-invalid': submitted && validate.form.prize_shipping_period.$error
                            }"
                            placeholder="●年●月●旬頃"
                        />
                        <div v-if="submitted && validate.form.prize_shipping_period.$error" class="invalid-feedback">
                            <span v-if="!validate.form.prize_shipping_period.maxLength">
                                {{
                                    $t('validateField.max255', {
                                        field: '賞品進呈時期(発送時期・送付時期)'
                                    })
                                }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.prizes_and_winning_information" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="group__7 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup7') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3">お問い合わせ事務局名 </label>
                    <div class="col-sm-9">
                        <textarea
                            class="form-control"
                            v-model="form.inquiry_office"
                            :class="{
                                'is-invalid': submitted && validate.form.inquiry_office.$error
                            }"
                        ></textarea>
                        <div v-if="submitted && validate.form.inquiry_office.$error" class="invalid-feedback">
                            <span v-if="!validate.form.inquiry_office.maxLength">
                                {{
                                    $t('validateField.max255', {
                                        field: 'お問い合わせ事務局名'
                                    })
                                }}
                            </span>
                        </div>
                    </div>
                </div>

                <div v-for="item in listContactInformations" :key="item.id + 's'">
                    <div v-if="form.contact_informations.includes(item.id)" class="form-group row">
                        <label class="col-sm-3">{{ item.subText }}</label>
                        <div class="col-sm-9">
                            <div v-if="item.child && item.child.length > 0">
                                <div v-for="(oChild, idoChild) in item.child" :key="idoChild" class="col-sm-9">
                                    <div v-if="form.contact_informations.includes(oChild.id)">
                                        <div class="form-group row">
                                            <label class="col-sm-2 text-nowrap" style="min-width: 90px">{{ oChild.text }}</label>
                                            <div class="col-sm-9">
                                                <InputText
                                                    :model.sync="tiContactInformations[oChild.id]"
                                                    :config.sync="oChild.configInput"
                                                    :disabled="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <InputText
                                        v-if="item.subText !== 'その他'"
                                        :model.sync="contact_informations[item.id]"
                                        :config.sync="item.configInput"
                                        :disabled="['電話番号（確定）'].includes(item.subText)"
                                    />
                                    <TextareaComponent v-else :model.sync="contact_informations[item.id]" :rows="`2`" :disabled="true">
                                    </TextareaComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.inquiries" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <!-- 【フォルダ・チャット関連】 -->
        <div class="group__8 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup8') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3" for="ChatworkRoom">Chatwork Room </label>
                    <div class="col-sm-8">
                        <div v-for="(itemChat, idx) in form.chatwork_rooms" :key="idx" class="d-flex align-items-center mb-3">
                            <div class="col-sm-12 px-0">
                                <input
                                    id="ChatworkRoom"
                                    v-model.number="itemChat.room_id"
                                    type="number"
                                    class="form-control mb-3"
                                    placeholder="チャットルームID"
                                    @keypress="handleNumber($event)"
                                />
                                <input
                                    id="ChatworkRoom"
                                    v-model="itemChat.room_name"
                                    type="text"
                                    class="form-control"
                                    :class="{
                                        'is-invalid': submitted && validate.form.chatwork_rooms.$each[idx].room_name.$error
                                    }"
                                    placeholder="チャット名"
                                />
                                <div v-if="submitted && validate.form.chatwork_rooms.$error" class="invalid-feedback">
                                    <span v-if="!validate.form.chatwork_rooms.$each[idx].room_name.maxLength">
                                        {{
                                            $t('validateField.max255', {
                                                field: ''
                                            })
                                        }}
                                    </span>
                                </div>
                            </div>

                            <div class="col-sm-1">
                                <button
                                    type="button"
                                    class="btn btn-outline-info btn-sm"
                                    style="border-radius: 50%"
                                    v-if="idx <= 0"
                                    @click="addChatRoom()"
                                >
                                    <i class="fa fa-plus"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-outline-danger"
                                    style="border-radius: 50%"
                                    v-if="idx > 0"
                                    @click="removeChatRoom(idx)"
                                >
                                    <i class="fa fa-minus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.projectFolder') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="form.folder_project"
                            :config="{
                                isMaxLength: !validate.form.folder_project.maxLength,
                                error: submitted && validate.form.folder_project.$error,
                                errorField: $t('pageCampaign.projectFolder')
                            }"
                            @change="submittedTab1 = false"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.quoteFolder') }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :model.sync="form.folder_quotation"
                            :config="{
                                errorField: $t('pageCampaign.quoteFolder'),
                                isMaxLength: !validate.form.folder_quotation.maxLength,
                                error: submitted && validate.form.folder_quotation.$error
                            }"
                            @change="submittedTab1 = false"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.folder_chat_related" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="group__9 my-3">
            <h5 class="font-weight-bold">{{ $t('pageCampaign.tabBasic.titleGroup9') }}</h5>
            <div class="ml-4">
                <div class="form-group row">
                    <label class="col-sm-3">検索キーワード</label>
                    <div class="col-sm-9">
                        <KeyWordTag :config="configKeyword" />
                        <span v-if="configKeyword.error" class="text-danger">
                            {{
                                $t('validateField.max255', {
                                    field: $t('client.keyword')
                                })
                            }}
                        </span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">メモ欄</label>
                    <div class="col-sm-9">
                        <textarea class="form-control" v-model="form.memo"></textarea>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">{{ this.$t('pageCampaign.associated_campaign_ID') }}</label>
                    <div class="col-sm-5">
                        <div class="d-flex align-items-center">
                            <Multiselect
                                :id="`select-associated-campaign-id`"
                                :value.sync="form.associated_campaign"
                                :options="listAssociatedCampaign"
                                :config="configAssociatedCampaign"
                                @open="handleOpenAssociatedCampaign()"
                            />

                            <div v-if="form.associated_campaign" class="ml-3">
                                <a target="_blank" :href="`/campaign/form/view?id=${form.associated_campaign.id}`" style="font-size: 20px">
                                    <i class="ri-links-line"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.tabBasic.freeDescription') }}</label>
                    <div class="col-sm-5">
                        <TextareaComponent :model.sync="form.campaign_content_other.others" :rows="`5`"> </TextareaComponent>
                    </div>
                </div>
            </div>
        </div>

        <ModalScopeOrder
            :dataForm="form"
            :dataMasterOrderScope="listMasterOrderScope"
            :isShow.sync="hasShowModalScope"
            :dataScope="dataObject?.campaign_master_order_scopes ?? []"
            :dataContactInformations="contact_informations"
            @saveScopeOrderModal="handleSaveScopeOrder"
            @changeOrderLv="handleChangeLv"
        />
    </div>
</template>
<script>
import KeyWordTag from '@/components/KeywordTag/main.vue';
import DateTime from '@/components/DateCustoms/DateTime/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import ModalScopeOrder from './components/ModalScopeOrder/main.vue';
import TextareaComponent from '@/components/Textarea/main.vue';
import KeyWordTag2 from '@/components/KeywordTag/main2.vue';

import { InputText, InputRadio, InputCheckBox } from '@/components/Input';
import { campaignMethods, masterMethods } from '@/state/helpers';
import { isNumber } from '@/utils/format';
import { listContactInformations } from '@/config/var-common';
import { LIST_INQUIRY_FIT, LIST_CHILD_DM } from '@/config/constants';
import { funcSetCondition1 } from './index';
import { initObjectTargetProduct, onHandleIsVisibleOtherC2 } from '@/views/pages/campaign/edit/index';
export default {
    name: 'InformationBasic',
    components: {
        KeyWordTag,
        DateTime,
        Multiselect,
        InputCheckBox,
        InputRadio,
        InputText,
        ModalScopeOrder,
        TextareaComponent,
        KeyWordTag2
    },
    props: {
        campaignStatus: {
            type: Array
        },
        progressStatusS: {
            type: Object
        },
        listDataMasterCampaignProject: {
            type: Array
        },
        listDataMasterCampaignType: {
            type: Array
        },
        listDataMasterCampaignTypeChilds: {
            type: Array
        },
        listItemPersons: {
            type: Array
        },
        listCategoriesSubs: {
            type: Array
        },
        configKeyword: {
            type: Object
        },
        listCategoriesS: {
            type: Array
        },
        contact_informations: {
            type: Object
        },
        hashTag: {
            type: Object
        },
        dataObject: {
            type: Object
        },
        purchase_conditions: {
            type: Object
        },
        snsApplicationCondition1: {
            type: Object
        },
        submitted: {
            type: Boolean,
            required: true
        },
        validate: {
            type: Object
        },
        configTabBasic: {
            type: Object
        },
        listDataMasterConditionApplySNS: {
            type: Array
        },
        listDataMasterConditionPurchase1: {
            type: Array
        },
        listDataMasterCategoryProductTarget: {
            type: Array
        },
        listDataMasterOrderScope: {
            type: Array
        },
        listIPInformationItem: {
            type: Array
        },
        listSponsorInformation: {
            type: Array
        }
    },
    data: function () {
        return {
            form: this.dataObject,
            listCategoriesSub: this.listCategoriesSubs,
            progressStatus: this.progressStatusS,
            listDataMasterCampaignTypeChild: this.listDataMasterCampaignTypeChilds,
            listCategories: this.listCategoriesS,
            sns_application_condition_1: {},
            listContactInformations: listContactInformations,
            purchaseConditions: this.purchase_conditions,
            listMasterOrderScope: this.listDataMasterOrderScope,
            listChecked: {
                msOrderLv2: [],
                msOrderLv3: []
            },
            hasShowModalScope: false,
            listAssociatedCampaign: [],
            configAssociatedCampaign: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: false,
                loading: false,
                openDirection: 'top'
            },
            submittedTab1: this.submitted,
            tiContactInformations: { ...this.contact_informations },
            configFieldHashtags: {
                maxLength: 255,
                error: false,
                errorField: this.$t('landingPage.settingBasic.keyword'),
                errorCustomMess: '',
                valueSync: [],
                typesValidate: []
            }
        };
    },
    watch: {
        listCategoriesSubs(newValue) {
            this.listCategoriesSub = newValue;
        },
        listDataMasterCampaignTypeChilds(newValue) {
            this.listDataMasterCampaignTypeChild = newValue;
        },
        progressStatusS(newValue) {
            this.progressStatus = newValue;
        },
        dataObject(newValue) {
            this.form = newValue;
        },
        snsApplicationCondition1(newValue) {
            this.sns_application_condition_1 = newValue;
        },
        'dataObject.campaign_type': {
            handler: function () {
                if (this.form.campaign_type.value === this.$t('pageCampaign.closeCp')) {
                    this.form.isShowReceiptExpirationDate = true;
                    if (this.listCategories.length > 0) {
                        const findObjCondition1 = this.listCategoriesSub.find((item) => item.application_condition_1);
                        if (findObjCondition1) {
                            if (findObjCondition1.listCondition2.length > 0 && findObjCondition1.application_condition_2.length > 0) {
                                this.handleShowConditionBuyAndSell('watch');
                            }
                        }
                    }
                } else {
                    this.form.isShowReceiptExpirationDate = false;
                }
                this.handleShowDatePostCard();
            },
            immediate: true
        },
        listCategoriesS: {
            handler: function (newValue) {
                this.listCategories = newValue;
            },
            immediate: true,
            deep: true
        },
        progressStatus: {
            handler: function (newName) {
                this.selectObject(newName, 'progress_status');
            },
            immediate: true
        }
    },
    mounted() {},
    methods: {
        ...campaignMethods,
        ...masterMethods,
        onHandleIsVisibleOtherC2,

        selectObject(data, key, key1) {
            if (data) {
                if (key1) this.form[key][key1] = data.id;
                else this.form[key] = data.id;
            } else {
                if (key1) this.form[key][key1] = '';
                else this.form[key] = '';
            }
        },

        deleteCategory(item) {
            let i = this.listCategories.findIndex((x) => {
                return x == item.category_id;
            });
            if (i >= 0) {
                this.listCategories.splice(i, 1);

                let index = this.listCategoriesSub.findIndex((x) => {
                    return x.category_id == item.category_id;
                });
                if (index >= 0) {
                    this.listCategoriesSub.splice(index, 1);
                }
            }
        },

        setCondition1() {
            const resultSet1 = funcSetCondition1(this.listCategoriesSub, this.listCategories, this.listDataMasterCampaignTypeChild, this.form);
            this.listCategoriesSub = resultSet1.listCategoriesSub;
            this.listCategories = resultSet1.listCategories;
            this.listDataMasterCampaignTypeChild = resultSet1.listDataMasterCampaignTypeChild;
            this.form = resultSet1.form;
            this.$emit('update:listCategoriesSubs', this.listCategoriesSub);
            this.handleShowConditionBuyAndSell('change');
            this.handleShowDatePostCard();
        },

        setCondition2(item) {
            if (!item.application_condition_1) {
                return;
            }
            item.application_condition_2 = [];
            let index = item.listCondition1.findIndex((x) => {
                return x.id == item.application_condition_1.id;
            });
            if (index >= 0) {
                item.listCondition2 = item.listCondition1[index].data.map((ic2) => {
                    ic2.note = {
                        other_content: ''
                    };
                    return ic2;
                });
            }
        },

        handleRemove(item) {
            item.application_condition_2 = [];
            this.handleShowConditionBuyAndSell('change');
        },

        getDataCampaignTye(obj) {
            this.form.campaign_type.value = obj.value;
            this.listCategoriesSub = [];
            this.listCategories = [];
            this.form.isShowConditionBuyAndSell = false;
            this.form.purchase_conditions = [];
            this.form.application_form = '';
            this.form.campaign_start_date = '';
            this.form.campaign_end_date = '';
            this.form.secretariat_start_date = '';
            this.form.secretariat_end_date = '';
            this.form.receipt_expiration_start_date = '';
            this.form.receipt_expiration_end_date = '';

            let index = this.listDataMasterCampaignType.findIndex((x) => {
                return x.id == this.form.campaign_type.id;
            });
            if (index >= 0) {
                this.listDataMasterCampaignTypeChild = this.listDataMasterCampaignType[index].data;
            }

            this.form.isShowReceiptExpirationDate = this.form.campaign_type.value === this.$t('pageCampaign.closeCp');

            this.form.target_product_lists = [];
            this.form.listCheckedTargetLv1 = [];
            this.form.listCheckedTargetLv2 = [];
            this.handleShowDatePostCard();
            this.$emit('update:listCategoriesSubs', this.listCategoriesSub);
        },

        renderNameCategory(item) {
            let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == item.category_id;
            });
            if (index >= 0) {
                return this.listDataMasterCampaignTypeChild[index].value;
            }
        },

        checkShow(type) {
            let i = this.listDataMasterCampaignType.findIndex((x) => {
                return x.id == type;
            });
            if (i >= 0 && this.listDataMasterCampaignType[i].value != 'オープン') {
                return true;
            }
            return false;
        },

        handleNumber(e) {
            isNumber(e);
        },

        addChatRoom() {
            this.form.chatwork_rooms.push({
                room_id: '',
                room_name: ''
            });
        },

        removeChatRoom(k) {
            this.form.chatwork_rooms.splice(k, 1);
        },

        renderNamePurchase(i) {
            let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.listDataMasterConditionPurchase1[index].value;
            }
            return '';
        },

        handleShowPublicTime() {
            // 95 LPサイト
            if (this.listChecked.msOrderLv3.includes(95)) {
                this.form.isShowPublicTime = true;
            } else {
                this.form.isShowPublicTime = false;
                this.form.url_publish_time_from = '';
                this.form.url_publish_time_to = '';
            }
        },

        handleShowConditionBuyAndSell(typeHandle = null, listC2 = [], itemC2 = null) {
            if (this.form.campaign_type.value !== this.$t('pageCampaign.closeCp')) return;
            if (typeHandle === 'change') {
                if (!this.form.isShowConditionBuyAndSell) {
                    this.form.purchase_conditions = [];
                    this.purchaseConditions = {};
                }
                const removeNote = listC2.find((oL2) => oL2.id === itemC2.id);
                if (!removeNote) return;
                removeNote.note.other_content = '';
            }

            this.dataApplycation2Type1 = this.listCategoriesSub.map((item) => item.listCondition2).flat();
            this.listIDSelectApplycation2Type1 = this.listCategoriesSub
                .map((item) => item.application_condition_2)
                .flat()
                .map(Number);

            const listSelected = this.dataApplycation2Type1.filter((itemAPT2) => this.listIDSelectApplycation2Type1.includes(itemAPT2.id));
            if (!listSelected.length) {
                this.form.isShowConditionBuyAndSell = false;
            } else {
                const listValue = listSelected.map((itemSelected) => itemSelected.value);
                this.form.isShowConditionBuyAndSell = listValue.includes('WEB明細') || listValue.includes('レシート（購買証明）');
            }
        },

        handleShowDatePostCard() {
            if (this.listCategories.length > 0) {
                const appSelected = this.listDataMasterCampaignTypeChild.filter((item) => this.listCategories.includes(item.id));
                this.form.isShowPostcardDate = appSelected?.some((sl) => sl.value === 'ハガキ');
                if (!this.form.isShowPostcardDate) {
                    this.form.is_post_effective_date = false;
                    this.form.is_before_due_date = false;
                }
            } else {
                this.form.isShowPostcardDate = false;
                this.form.is_post_effective_date = false;
                this.form.is_before_due_date = false;
            }
        },

        handleChangeDataTargetProductLv1(obj) {
            if (this.form.listCheckedTargetLv1.includes(obj.id)) {
                this.form.target_product_lists.push(initObjectTargetProduct(obj, 'change'));
            } else {
                for (const targetChild of obj.data) {
                    this.form.listCheckedTargetLv2 = this.form.listCheckedTargetLv2.filter((checked2) => checked2 !== targetChild.id);
                }
                this.form.target_product_lists = this.form.target_product_lists.filter((itemTarget) => itemTarget.master_id !== obj.id);
            }
        },

        handleChangeDataTargetProductLv2(objLv2, idLv1) {
            const foundObj = this.form.target_product_lists.find((itemTP) => itemTP.master_id == idLv1);
            if (this.form.listCheckedTargetLv2.includes(objLv2.id)) {
                foundObj.children_data.push({
                    master_id: objLv2.id,
                    value: objLv2.value,
                    note: {
                        product_name: objLv2?.note?.product_name ?? ''
                    },
                    children_data: objLv2?.data ?? []
                });
            } else {
                foundObj.children_data = foundObj.children_data.filter((lv2) => lv2.master_id !== objLv2.id);
            }
        },

        handleGetNoteTargetProduct(objLv2, idLv1) {
            const foundObj = this.form.target_product_lists.find((itemTP) => itemTP.master_id == idLv1);
            if (foundObj) {
                const foundObj2 = foundObj.children_data.find((item2) => item2.master_id == objLv2);
                if (foundObj2) {
                    return foundObj2.note;
                }
            }
        },

        handleClearDataPurchaseConditions(item) {
            if (!this.form.purchase_conditions.includes(item.id)) {
                this.purchaseConditions[item.id] = '';
            }
        },

        handleOpenScopeOrder() {
            this.hasShowModalScope = true;
        },

        handleShowContactInformation(inforScope) {
            let { contentOther } = inforScope;
            if (this.listChecked.msOrderLv2.length > 0) {
                let childLv2 = this.listChecked.msOrderLv2.filter((item) => LIST_INQUIRY_FIT.includes(item));
                let childLv3 = this.listChecked.msOrderLv3.filter((item) => LIST_CHILD_DM.includes(item));

                this.form.contact_informations = [...childLv2, ...childLv3];
            } else {
                this.form.contact_informations = [];
            }

            // logic cũ
            // if (this.contact_informations) {
            //                 for (const keyContact in this.contact_informations) {
            //                     if (!this.form.contact_informations.includes(Number(keyContact))) {
            //                         this.contact_informations[keyContact] = '';
            //                     }
            //                 }
            //             }

            // logic mới
            const getDM = this.listContactInformations.find((itemL) => itemL.text === 'DM');
            this.tiContactInformations = {
                ...this.tiContactInformations,
                ...Object.fromEntries(this.form.contact_informations.map((key) => [key, '']))
            };
            if (this.tiContactInformations) {
                for (const keyContact in this.tiContactInformations) {
                    if (LIST_CHILD_DM.includes(Number(keyContact))) {
                        this.listCategoriesSub.forEach((oT) => {
                            if (oT.checkSNS >= 0) {
                                const objDM = getDM.child.find((ob) => ob.text === oT.category_value);
                                if (objDM) {
                                    this.tiContactInformations[objDM.id] =
                                        `${oT.extension.offical_name_sns ?? ''} ${oT.extension.offical_id_sns ?? ''}`;
                                }
                            }
                        });
                    }
                }
            }

            // ['9', '10', '11', '12'] || X, Instagram, TikTok, LINE những giá trị trên được lấy nội dung nhập từ 公式アカウント名 và 公式アカウントID
            // ['5'] không cập nhật lại trong scope order
            for (const coten in contentOther) {
                if (!['9', '10', '11', '12', '5'].includes(coten)) this.tiContactInformations[coten] = contentOther[coten];
            }

            for (const property in this.contact_informations) {
                if (['2', '5'].includes(property)) this.tiContactInformations[property] = this.contact_informations[property];
            }
            this.$emit('update:contact_informations', this.tiContactInformations);
            // logic mới
        },

        handleChangeSocialID(objS) {
            let { category_value, extension } = objS;
            const getDM = this.listContactInformations.find((itemL) => itemL.text === 'DM');
            const objDM = getDM.child.find((ob) => ob.text === category_value);
            if (objDM) {
                this.tiContactInformations[objDM.id] = `${extension.offical_name_sns ?? ''} ${extension.offical_id_sns ?? ''}`;
            }
        },

        async handleOpenAssociatedCampaign() {
            if (this.configAssociatedCampaign.loading || this.listAssociatedCampaign.length > 0) return;
            this.configAssociatedCampaign.loading = true;
            try {
                const dataRes = await this.listMaster('all/campaigns');
                if (dataRes && dataRes.length > 0) {
                    this.listAssociatedCampaign = dataRes.filter((item) => item.id !== this.form.id);
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                this.configAssociatedCampaign.loading = false;
            }
        },

        handleChangeLv(paramEmit) {
            this.listChecked.msOrderLv3 = paramEmit.scopeChecked.msOrderLv3;
            this.handleShowPublicTime();
        },

        handleSaveScopeOrder(objEmit) {
            this.listChecked.msOrderLv2 = objEmit.scopeChecked.msOrderLv2;
            this.listChecked.msOrderLv3 = objEmit.scopeChecked.msOrderLv3;
            this.$emit('saveScopeOrder', objEmit);
            this.handleShowContactInformation(objEmit);
            this.handleShowPublicTime();
        },

        handleChangeRadioInfomationIP(information_ips0, itemIPValue) {
            information_ips0.value = itemIPValue;
            information_ips0.checkedBox = [];
            information_ips0.children_data = [];
        },

        handleChangeListDataIP(information_ips0, dataItem) {
            if (information_ips0.checkedBox.includes(dataItem?.id)) {
                information_ips0.children_data.push({
                    master_id: dataItem?.id,
                    value: dataItem?.value,
                    note: '',
                    children_data: []
                });
            } else {
                information_ips0.children_data = information_ips0.children_data.filter((item) => item.master_id !== dataItem?.id);
            }
        },

        handleChangeSponsor(sponsor_informations0, sponsorValue) {
            sponsor_informations0.value = sponsorValue;
            sponsor_informations0.note.circulation_number = '';
            sponsor_informations0.note.circulation_distribution = '';
            sponsor_informations0.note.content_other = '';
            sponsor_informations0.note.up_manufacturers = '';
            sponsor_informations0.note.up_manufacturers_name = '';
        },

        handleCampaignDateTime(type) {
            let { campaign_start_date, campaign_end_date, campaign_start_time, campaign_end_time } = this.form;

            switch (type) {
                case 'start':
                    this.form.secretariat_start_date = campaign_start_date;
                    this.form.receipt_expiration_start_date = campaign_start_date;
                    this.form.receipt_expiration_start_time = campaign_start_time;
                    break;
                case 'end':
                    this.form.receipt_expiration_end_date = campaign_end_date;
                    this.form.receipt_expiration_end_time = campaign_end_time;

                    break;
            }
        }
    },
    computed: {}
};
</script>

<style lang="scss" scoped></style>
