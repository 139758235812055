<template>
    <div>
        <ModalCommon id="ModalSettingAllPartner" :config="configProp" @save="handleSaveScopeTool()" @close="handleCloseScopeTool()" :isView="false">
            <div class="col-sm-12">
                <div class="form-group row">
                    <label class="col-sm-3">大項目</label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-major_item`"
                            :value.sync="formApp.major_item"
                            :options="dataScope"
                            :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                            @select="handledSelectMajor"
                            @remove="handleRemoveMajor"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">中項目</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div v-for="item in formApp?.major_item?.data" :key="item.id + 'medium--item'">
                                <InputCheckBox
                                    class="mr-3 mb-2"
                                    :model.sync="formApp.medium_items"
                                    :id="`check-medium--item${item.id}`"
                                    :value="item.id"
                                    :label="item.value"
                                    @change="handleCheckMedium"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">パートナー</label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-user_partner`"
                            :value.sync="formApp.user_partner"
                            :options="listUserPartner"
                            :config.sync="configSelectUserPartner"
                            @open="handleOpenUserPartner"
                        />
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputCheckBox } from '@/components/Input';
import { partnersMethods } from '@/state/helpers';

import ModalCommon from '@/components/Modal/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

export default {
    name: 'ModalSettingPartner',
    components: {
        ModalCommon,
        Multiselect,
        InputCheckBox
    },
    props: {
        configProp: {
            type: Object,
            default: () => null
        },
        dataScope: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            formApp: {
                major_item: null,
                medium_items: [],
                user_partner: null
            },
            listUserPartner: [],
            configSelectUserPartner: { trackBy: 'id', label: 'name', error: false, isRemove: false, loading: false }
        };
    },
    methods: {
        ...partnersMethods,

        handleCloseScopeTool() {
            this.configProp.model = false;
        },

        async handleSaveScopeTool() {
            let { major_item, medium_items, user_partner } = this.formApp;
            if (!major_item || (major_item && major_item?.data?.length > 0 && medium_items?.length <= 0) || !user_partner) return;
            this.$emit('dataApply', this.formApp);
        },

        handledSelectMajor() {
            this.formApp.medium_items = this.formApp.major_item?.data?.map((oM) => oM.id);
            this.formApp.user_partner = null;
        },

        handleRemoveMajor() {
            this.formApp.medium_items = [];
            this.formApp.user_partner = null;
        },

        handleCheckMedium() {
            this.formApp.user_partner = null;
        },

        async handleOpenUserPartner() {
            this.listUserPartner = [];
            let { major_item, medium_items } = this.formApp;
            if (!major_item || (major_item && major_item?.data?.length > 0 && medium_items?.length <= 0) || this.configSelectUserPartner.loading)
                return;
            this.configSelectUserPartner.loading = true;
            try {
                let queryFilter = [`limit=9999999`];
                if (medium_items?.length > 0) {
                    medium_items;
                    queryFilter.push(`has[medium_item]=${medium_items}`);
                } else {
                    queryFilter.push(`has[large_item]=${major_item?.id}`);
                }
                const resultPartnerCorresponding = await this.CHECK_PARTNER_ORDER_SCOPE_CAMPAIGN(queryFilter.join('&'));
                let { code, data } = resultPartnerCorresponding;
                if (code !== 200) return;
                this.listUserPartner = data;
            } catch (error) {
                console.log('error', error);
            } finally {
                this.configSelectUserPartner.loading = false;
            }
        }
    },
    watch: {
        'configProp.model': {
            handler: function (newModel) {
                if (newModel) {
                    this.formApp = {
                        major_item: null,
                        medium_items: [],
                        user_partner: null
                    };
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
