import { render, staticRenderFns } from "./main.vue?vue&type=template&id=7ef2a2c0&scoped=true"
import script from "./main.vue?vue&type=script&lang=js"
export * from "./main.vue?vue&type=script&lang=js"
import style0 from "./main.vue?vue&type=style&index=0&id=7ef2a2c0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef2a2c0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/Paldia/FE-Paldia-Dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ef2a2c0')) {
      api.createRecord('7ef2a2c0', component.options)
    } else {
      api.reload('7ef2a2c0', component.options)
    }
    module.hot.accept("./main.vue?vue&type=template&id=7ef2a2c0&scoped=true", function () {
      api.rerender('7ef2a2c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Campaign/TabPrize/components/ModalDetailsReturns/main.vue"
export default component.exports