<template>
    <div>
        <ModalCommon
            :id="`ModalOrder-${id}`"
            :config="configModalCommon"
            @save="handleSavePrevScope()"
            @close="handleClosePrevScope()"
            :isView="isView"
        >
            <div class="form-group row master--scope__sticky">
                <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                        <div style="min-width: 100px" v-for="orderLv1 in listMasterLevel1" :key="orderLv1.id">
                            <InputCheckBox
                                class="mr-4 py-1"
                                :model.sync="listChecked"
                                :id="`check_app${orderLv1.id}`"
                                :value="orderLv1.id"
                                :label="orderLv1.value"
                                :disabled="isView"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="itemFilterLv1 in listMasterLevel1" :key="`${itemFilterLv1.id}-filter1`">
                <div
                    v-if="itemFilterLv1?.childrens?.length > 0"
                    class="py-3 mt-3"
                    style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                >
                    <div>
                        <div class="ml-1 my-4">
                            <label class="custom-control px-3">{{ itemFilterLv1.value }} </label>
                        </div>
                        <div class="form-group">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-1"></div>
                                    <div class="row col-sm-11">
                                        <div v-for="(dataFilterLv1, idxF1) in listMasterLevel2" :key="`${idxF1}-medium_item_filter1`">
                                            <div v-if="dataFilterLv1.parent_id === itemFilterLv1.id">
                                                <InputCheckBox
                                                    class="mr-4 py-1"
                                                    style="min-width: 100px"
                                                    :model.sync="listChecked"
                                                    :id="`check_app${dataFilterLv1.id}`"
                                                    :value="dataFilterLv1.id"
                                                    :label="dataFilterLv1.value"
                                                    :disabled="isView"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-for="itemFilterLv2 in listMasterLevel2" :key="`${itemFilterLv2.id}-filter2`">
                                    <div
                                        v-if="itemFilterLv2?.childrens?.length > 0 && itemFilterLv2.parent_id === itemFilterLv1.id"
                                        style="border-top: 1px solid rgb(181 180 180 / 50%)"
                                        class="mt-3"
                                    >
                                        <div class="ml-4 my-4">
                                            <label class="custom-control px-2"
                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span>
                                                {{ itemFilterLv2.value }}</label
                                            >
                                        </div>

                                        <div class="form-group">
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-1"></div>
                                                    <div class="row">
                                                        <div v-for="(dataFilterLv3, idxF3) in listMasterLevel3" :key="`${idxF3}-medium_item_filter2`">
                                                            <div v-if="dataFilterLv3.parent_id === itemFilterLv2.id">
                                                                <div v-if="funcOptionOneFilterLv2(itemFilterLv2)">
                                                                    <InputRadio
                                                                        class="mr-4"
                                                                        :model.sync="listRadio[itemFilterLv2.id]"
                                                                        :name="`msOrderLv2-${dataFilterLv3.id}`"
                                                                        :id="`radio-${dataFilterLv3.id}`"
                                                                        :value="dataFilterLv3.id"
                                                                        :label="dataFilterLv3.value"
                                                                        :disabled="isView"
                                                                    />
                                                                </div>
                                                                <div v-else>
                                                                    <InputCheckBox
                                                                        class="mr-4 py-1"
                                                                        style="min-width: 100px"
                                                                        :model.sync="listChecked"
                                                                        :id="`check_app${dataFilterLv3.id}`"
                                                                        :value="dataFilterLv3.id"
                                                                        :label="dataFilterLv3.value"
                                                                        :disabled="isView"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-for="itemFilterLv3 in listMasterLevel3" :key="`${itemFilterLv3.id}-filter2`">
                                                    <div
                                                        v-if="itemFilterLv3?.childrens?.length > 0 && itemFilterLv3.parent_id === itemFilterLv2.id"
                                                        style="border-top: 1px solid rgb(181 180 180 / 50%)"
                                                        class="mt-3"
                                                    >
                                                        <div class="ml-4 my-4">
                                                            <label class="custom-control"
                                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">></span>
                                                                {{ itemFilterLv2.value }}
                                                                <span class="font-weight-bold px-1">></span>
                                                                {{ itemFilterLv3.value }}
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="col-sm-12">
                                                                <div class="row">
                                                                    <div class="col-sm-1"></div>
                                                                    <div class="row">
                                                                        <div
                                                                            v-for="(dataFilterLv4, idxF4) in listMasterLevel4"
                                                                            :key="`${idxF4}-medium_item_filter2`"
                                                                        >
                                                                            <div v-if="dataFilterLv4.parent_id === itemFilterLv3.id">
                                                                                <div v-if="funcOptionOneFilterLv3(itemFilterLv3)">
                                                                                    <InputRadio
                                                                                        class="mr-4"
                                                                                        :model.sync="listRadio[itemFilterLv3.id]"
                                                                                        :name="`msOrderLv3-${dataFilterLv4.id}`"
                                                                                        :id="`radio-${dataFilterLv4.id}`"
                                                                                        :value="dataFilterLv4.id"
                                                                                        :label="dataFilterLv4.value"
                                                                                        :disabled="isView"
                                                                                    />
                                                                                </div>
                                                                                <div v-else>
                                                                                    <InputCheckBox
                                                                                        class="mr-4 py-1"
                                                                                        style="min-width: 100px"
                                                                                        :model.sync="listChecked"
                                                                                        :id="`check_app${dataFilterLv4.id}`"
                                                                                        :value="dataFilterLv4.id"
                                                                                        :label="dataFilterLv4.value"
                                                                                        :disabled="isView"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import { InputCheckBox, InputRadio } from '@/components/Input';

import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'ModalScopeOrderPrev',
    components: {
        ModalCommon,
        InputCheckBox,
        InputRadio
    },
    props: {
        id: {
            type: String,
            default: () => ''
        },
        dataMasterOrderScope: {
            type: Array,
            default: () => []
        },
        isShow: {
            type: Boolean,
            default: false
        },
        isView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                size: '2xl',
                class: 'modal-custom-tab-basic-cp'
            },
            listChecked: [],
            listRadio: {},
            listMasterLevel1: [],
            listMasterLevel2: [],
            listMasterLevel3: [],
            listMasterLevel4: []
        };
    },
    methods: {
        handleSavePrevScope() {
            this.$emit('update:isShow', false);
        },

        handleClosePrevScope() {
            this.$emit('update:isShow', false);
        },

        handleMapCheckScope(masterScopeData) {
            masterScopeData?.forEach((item) => {
                this.listChecked.push(item.id);
                if (item.parent_id) this.listRadio[item.parent_id] = item.id;
                if (item?.data) {
                    this.handleMapCheckScope(item?.data);
                }
            });
            // console.log('this.listChecked', this.listChecked);
            // console.log('this.listRadio', this.listRadio);
        },

        splitLevels(data, level = 0, result = []) {
            if (!result[level]) result[level] = []; // Tạo mảng cho từng cấp nếu chưa có
            data.forEach((item) => {
                result[level].push({
                    id: item.id,
                    value: item.value,
                    origin_parent_id: item.origin_parent_id,
                    parent_id: item.parent_id,
                    childrens: item.data
                }); // Đẩy vào level tương ứng
                if (item.data && item.data.length > 0) {
                    this.splitLevels(item.data, level + 1, result); // Đệ quy xuống cấp tiếp theo
                }
            });
            return result;
        },

        funcOptionOneFilterLv2(itemFilterLv2) {
            let listShotCode2 = ['電話', 'メール', 'はがき回収', 'WEBフォーム', '応募数報告', 'お問い合わせ報告', '現物', '当選者用フォーム'];
            return listShotCode2.includes(itemFilterLv2?.value);
        },

        funcOptionOneFilterLv3(itemFilterLv3) {
            let listShotCode3 = ['応募数報告', 'お問い合わせ報告', '現物'];
            return listShotCode3.includes(itemFilterLv3.value);
        }
    },
    watch: {
        isShow: {
            handler: function (newIsShow) {
                this.configModalCommon.model = newIsShow;
                if (newIsShow) {
                    this.listChecked = [];
                    this.handleMapCheckScope(this.dataMasterOrderScope);
                }
            },
            immediate: true,
            deep: true
        },

        dataMasterOrderScope: {
            handler: function (newData) {
                const [level1, level2, level3, level4] = this.splitLevels(newData);
                this.listMasterLevel1 = level1;
                this.listMasterLevel2 = level2;
                this.listMasterLevel3 = level3;
                this.listMasterLevel4 = level4;
                // console.log('Level 1:', level1);
                // console.log('Level 2:', level2);
                // console.log('Level 3:', level3);
                // console.log('Level 4:', level4);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.master--scope__sticky {
    position: sticky;
    top: -20px;
    background-color: #ffffff;
    z-index: 99;
    padding-block: 1.5rem;
}
</style>
